.experience {
  padding: 2em;
  margin: 0 auto;
  text-align: center;
  box-shadow: var(--shadow);
  transition: transform 0.2s linear;
}

.experience:hover {
  transform: translateY(-7px);
}

.experience__title {
  margin-top: .2rem;
  font-size: 1rem;
  color: var(--clr-fg-subhead);
}
.experience__dates {
  margin-top: 0.1rem;
  font-size: 0.9rem;
  font-weight: 300;
  color: var(--clr-fg-subhead);
}
.experience__description {
  margin-top: .8em;
  justify-content: left;
}

.experience__stack {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 1.2em 0;
}

.experience__stack-item {
  margin: 0.5em;
  margin-top: 0.1em;
  font-weight: 500;
  font-size: 0.8rem;
  color: var(--clr-fg-alt);
}

.experience .link--icon {

}
